import classNames from 'classnames';
import React, { useState } from 'react';
import css from './ReviewCard.module.css';
import { ReactComponent as ProfileIcon } from '../../assets/ProfileIcon.svg';
import Modal from '../Modal/Modal';
export default function ReviewCard(props) {
  const {
    className,
    rootClassName,
    name,
    review,
    image,
    onManageDisableScrolling,
    charLimit,
    reviewModalText,
  } = props;

  const [isExpanded, setIsExpanded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [reviewToShow, setReviewToShow] = useState(null);
  const shortText =
    review.slice(0, charLimit) + (review.length > charLimit ? '...' : '');
  const showReadMoreBtn = review.length > charLimit;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.imgNameWrapper}>
        {image !== null ? (
          <>
            <img src={image} alt={''} className={css.profileIcon} />
          </>
        ) : (
          <ProfileIcon className={css.noProfileIcon} />
        )}
        <div className={css.name}>{name}</div>
      </div>
      <div className={classNames(css.review, css.lineReview)}>
        {/* {review} */}
        <div className={css.content}>
          {/* <p>{isExpanded ? review : shortText}</p> */}
          <p>{shortText}</p>
          {showReadMoreBtn ? (
            <button
              // onClick={() => setIsExpanded(!isExpanded)}
              onClick={() => {
                setShowModal(true);
                setReviewToShow(!!reviewModalText ? reviewModalText : review);
              }}
              className={css.readbtn}
            >
              {/* {isExpanded ? 'Show Less' : 'Read More'} */}
              <span>Read full review</span>
            </button>
          ) : null}
        </div>
      </div>
      <Modal
        id="reviewCard.reviewDetails"
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={classNames(css.review, css.modalReview)}>
          {reviewToShow && (
            <div className={css.modalContent}>{reviewToShow}</div>
          )}
        </div>
      </Modal>
    </div>
  );
}
