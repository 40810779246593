import React, { Component } from 'react';
import PropTypes, { object } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

import { NamedLink } from '../../components';

import css from './SectionCategory.module.css';

import food_and_Drinks from './images/Food_and_Drinks.png';
import traditional from './images/Traditional_new.jpg';
import clothing from './images/clothing_new.jpg';
import jewellery from './images/Jewellerynew.png';
import beauty from './images/BeautyNew.JPG';
import gifts from './images/Gifts_new.jpg';
import { PrimaryButton } from '../Button/Button';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

class categoryImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(categoryImage);

const SectionCategoryComponent = props => {
  const { rootClassName, className, history } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.title}>Featured categories</div>
      <div className={css.locationWrap}>
        <div className={css.locations}>
          <button
            type="button"
            className={css.catbtn}
            onClick={() => {
              const params = {
                pub_productCategory: 'foodDrinks',
              };
              history.push(
                createResourceLocatorString(
                  'SearchPage',
                  routeConfiguration(),
                  {},
                  params
                )
              );
            }}
          >
            <img src={food_and_Drinks} className={css.catimg2} />
            <span className={css.linkText}>Food & Drinks</span>
          </button>
          <button
            type="button"
            className={css.catbtn}
            onClick={() => {
              const params = {
                pub_productCategory: 'traditional',
              };
              history.push(
                createResourceLocatorString(
                  'SearchPage',
                  routeConfiguration(),
                  {},
                  params
                )
              );
            }}
          >
            <img src={traditional} className={css.catimg2} />
            <span className={css.linkText}>Traditional</span>
          </button>
          <button
            type="button"
            className={css.catbtn}
            onClick={() => {
              const params = {
                pub_productCategory: 'clothing',
              };
              history.push(
                createResourceLocatorString(
                  'SearchPage',
                  routeConfiguration(),
                  {},
                  params
                )
              );
            }}
          >
            <img src={clothing} className={css.catimg2} />
            <span className={css.linkText}>Clothing</span>
          </button>
          <button
            type="button"
            className={css.catbtn}
            onClick={() => {
              const params = {
                pub_productCategory: 'jewellery',
              };
              history.push(
                createResourceLocatorString(
                  'SearchPage',
                  routeConfiguration(),
                  {},
                  params
                )
              );
            }}
          >
            <img src={jewellery} className={css.catimg2} />
            <span className={css.linkText}>Jewellery</span>
          </button>
          <button
            type="button"
            className={css.catbtn}
            onClick={() => {
              const params = {
                pub_productCategory: 'beauty',
              };
              history.push(
                createResourceLocatorString(
                  'SearchPage',
                  routeConfiguration(),
                  {},
                  params
                )
              );
            }}
          >
            <img src={beauty} className={css.catimg2} />
            <span className={css.linkText}>Beauty</span>
          </button>
          <button
            type="button"
            className={css.catbtn}
            onClick={() => {
              const params = {
                pub_productCategory: 'gifts',
              };
              history.push(
                createResourceLocatorString(
                  'SearchPage',
                  routeConfiguration(),
                  {},
                  params
                )
              );
            }}
          >
            <img src={gifts} className={css.catimg2} />
            <span className={css.linkText}>Gifts</span>
          </button>

          {/* <button
          type="button"
          className={css.catbtn}
          onClick={() => {
            const params = {
              pub_productCategory: 'jewellery',
            };
            history.push(
              createResourceLocatorString(
                'SearchPage',
                routeConfiguration(),
                {},
                params
              )
            );
          }}
        >
          <img src={jewellery} className={css.catimg2} />
          <span className={css.linkText}>Jewellery</span>
        </button>
        <button
          type="button"
          className={css.catbtn}
          onClick={() => {
            const params = {
              pub_productCategory: 'beauty',
            };
            history.push(
              createResourceLocatorString(
                'SearchPage',
                routeConfiguration(),
                {},
                params
              )
            );
          }}
        >
          <img src={beauty} className={css.catimg2} />
          <span className={css.linkText}>Beauty</span>
        </button>
        <button
          type="button"
          className={css.catbtn}
          onClick={() => {
            const params = {
              pub_productCategory: 'gifts',
            };
            history.push(
              createResourceLocatorString(
                'SearchPage',
                routeConfiguration(),
                {},
                params
              )
            );
          }}
        >
          <img src={gifts} className={css.catimg2} />
          <span className={css.linkText}>Gifts</span>
        </button> */}
        </div>
        {/* <div className={css.locations}> */}
        {/* <button
          type="button"
          className={css.catbtn}
          onClick={() => {
            const params = {
              pub_productCategory: 'foodDrinks',
            };
            history.push(
              createResourceLocatorString(
                'SearchPage',
                routeConfiguration(),
                {},
                params
              )
            );
          }}
        >
          <img src={food_and_Drinks} className={css.catimg2} />
          <span className={css.linkText}>Food & Drinks</span>
        </button>
        <button
          type="button"
          className={css.catbtn}
          onClick={() => {
            const params = {
              pub_productCategory: 'traditional',
            };
            history.push(
              createResourceLocatorString(
                'SearchPage',
                routeConfiguration(),
                {},
                params
              )
            );
          }}
        >
          <img src={traditional} className={css.catimg2} />
          <span className={css.linkText}>Traditional</span>
        </button>
        <button
          type="button"
          className={css.catbtn}
          onClick={() => {
            const params = {
              pub_productCategory: 'clothing',
            };
            history.push(
              createResourceLocatorString(
                'SearchPage',
                routeConfiguration(),
                {},
                params
              )
            );
          }}
        >
          <img src={clothing} className={css.catimg2} />
          <span className={css.linkText}>Clothing</span>
        </button> */}
        {/* <button
            type="button"
            className={css.catbtn}
            onClick={() => {
              const params = {
                pub_productCategory: 'jewellery',
              };
              history.push(
                createResourceLocatorString(
                  'SearchPage',
                  routeConfiguration(),
                  {},
                  params
                )
              );
            }}
          >
            <img src={jewellery} className={css.catimg2} />
            <span className={css.linkText}>Jewellery</span>
          </button>
          <button
            type="button"
            className={css.catbtn}
            onClick={() => {
              const params = {
                pub_productCategory: 'beauty',
              };
              history.push(
                createResourceLocatorString(
                  'SearchPage',
                  routeConfiguration(),
                  {},
                  params
                )
              );
            }}
          >
            <img src={beauty} className={css.catimg2} />
            <span className={css.linkText}>Beauty</span>
          </button>
          <button
            type="button"
            className={css.catbtn}
            onClick={() => {
              const params = {
                pub_productCategory: 'gifts',
              };
              history.push(
                createResourceLocatorString(
                  'SearchPage',
                  routeConfiguration(),
                  {},
                  params
                )
              );
            }}
          >
            <img src={gifts} className={css.catimg2} />
            <span className={css.linkText}>Gifts</span>
          </button> */}
        {/* </div> */}
      </div>

      {/* <div className={css.browseBtnDiv}>
        <PrimaryButton
          className={css.browseBtn}
          onClick={e => {
            history.push(
              createResourceLocatorString(
                'SearchPage',
                routeConfiguration(),
                {}
              )
            );
          }}
        >
          Browse All
        </PrimaryButton>
      </div> */}
    </div>
  );
};

SectionCategoryComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

SectionCategoryComponent.propTypes = {
  rootClassName: string,
  history: object.isRequired,

  className: string,
};
const SectionCategory = compose(withRouter)(SectionCategoryComponent);
export default SectionCategory;
