import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Reviews } from '../../components';

import css from './ListingPage.module.css';
import { ReactComponent as StarFilled } from '../../assets/StarFilled.svg';
import { ReactComponent as StarUnfilled } from '../..//assets/StarUnfilled.svg';
import { ReactComponent as StarHalf } from '../..//assets/StarHalf.svg';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

const SectionReviews = props => {
  const { id, slug, history } = props;
  const {
    reviews,
    fetchReviewsError,
    avgReviewSection,
    fromBookingPanelListingReview,
  } = props;

  const reviewsError = (
    <h2 className={css.errorText}>
      <FormattedMessage id="ListingPage.reviewsError" />
    </h2>
  );

  const avgListingRating =
    reviews.length > 0
      ? reviews?.map(r => r.attributes?.rating)?.reduce((a, b) => a + b) /
        reviews.length
      : '';
  // const avgListingRating = 3.3;
  const avgReviewStars = avgListingRating => {
    const ratingStar = Array.from({ length: 5 }, (e, indx) => {
      let number = indx + 0.1;

      return (
        <span key={indx}>
          {avgListingRating >= indx + 1 ? (
            <StarFilled />
          ) : avgListingRating >= number ? (
            <StarHalf />
          ) : (
            <StarUnfilled />
          )}
        </span>
      );
    });
    return ratingStar;
  };
  return (
    <>
      {reviews ? (
        !!avgReviewSection ? (
          <div className={css.sectionReviews}>
            {!avgListingRating ? null : (
              <div>
                {avgReviewStars(avgListingRating)}{' '}
                {!fromBookingPanelListingReview ? (
                  <span
                    className={css.avgReviewText}
                    onClick={() => {
                      history.replace(
                        createResourceLocatorString(
                          id ? 'ProfilePage' : 'UserProfilePage',
                          routeConfiguration(),
                          id ? { id } : { slug },
                          { tab: 'reviews' }
                        )
                      );
                    }}
                  >
                    {/* {`(${reviews.length} Reviews)`} */}
                    {`(${reviews.length})`}
                  </span>
                ) : null}
              </div>
            )}
          </div>
        ) : (
          <div className={css.sectionReviews}>
            <h2 className={css.reviewsHeading}>
              <FormattedMessage
                id="ListingPage.reviewsHeading"
                values={{ count: reviews.length }}
              />
            </h2>
            {fetchReviewsError ? reviewsError : null}
            <Reviews reviews={reviews} />
          </div>
        )
      ) : null}
    </>
  );
};

export default SectionReviews;
