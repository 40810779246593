import React, { Component } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import MediaQuery from 'react-responsive';
import css from './SectionClothing.module.css';

import { FormattedMessage } from '../../util/reactIntl';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

// import css from './SectionClothing.module.css';
// import left from '../../../assets/boxLeft.svg';
// import right from '../../../assets/boxRight.svg';

// Update images by saving images to src/LandingPage/SeactionFilteredSearches/images directory.
// If those images have been saved with the same name, no need to make changes to these imports.

import { ReactComponent as Heart } from '../../assets/Wishlist.svg';
import { ReactComponent as ProfileIcon } from '../../assets/ProfileIcon.svg';

import Slider from 'react-slick';
import { PrimaryButton } from '../Button/Button';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { ListingCard } from '..';
import { FaArrowRight } from 'react-icons/fa';
import dresses from '../../assets/dresses.png';
import forbabies from '../../assets/forbabies.png';
import hoodies from '../../assets/hoodies.png';
import tShirts from '../../assets/tShirts.png';
import others from '../../assets/others.png';
// Thumbnail image for the search "card"
class ThumbnailImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
// Load the image only if it's close to viewport (user has scrolled the page enough).
const LazyImage = lazyLoadWithDimensions(ThumbnailImage);

// Component that shows full-width section on LandingPage.
// Inside it shows 3 "cards" that link to SearchPage with specific filters applied.
const SectionClothing = props => {
  const {
    rootClassName,
    className,
    history,
    clothingListings,
    setActiveListing,
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  const demoImages = [
    { img: dresses, key: 'dresses', label: 'Dresses' },
    { img: tShirts, key: 'tshirts', label: 'T-shirts' },
    { img: hoodies, key: 'hoodies', label: 'Hoodies' },
    { img: forbabies, key: 'baby', label: 'For Babies' },
    { img: others, key: 'other', label: 'Others' },
  ];

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: clothingListings.length < 4 ? clothingListings.length : 4,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          arrows: true,
          dots: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: true,
          dots: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          dots: false,
        },
      },
    ],
  };

  // FilterLink props:
  // - "name" is a string that defines what kind of search the link is going to make
  // - "image" is imported from images directory and you can update it by changing the file
  // - "link" should be copy-pasted URL from search page.
  //    The domain doesn't matter, but search query does. (I.e. "?pub_brand=nike")
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');
  return (
    <div className={classes}>
      <div className={css.title}>
        Clothing <FaArrowRight />
      </div>
      {/* <div className={`${css.filteredSearches} filteredSearches`}>
        <MediaQuery minWidth={768}>
          <Slider {...settings}>
            {clothingListings?.map((l, indx) => {
              {
                return (
                  <ListingCard
                    className={css.listingCard}
                    key={l.id.uuid}
                    listing={l}
                    renderSizes={cardRenderSizes}
                    setActiveListing={setActiveListing}
                    history={history}
                  />
                );
              }
            })}
          </Slider>
        </MediaQuery>
        <MediaQuery maxWidth={767}>
          {clothingListings.length > 4
            ? clothingListings
                .map(l => {
                  return (
                    <ListingCard
                      className={css.searchLink}
                      key={l.id.uuid}
                      listing={l}
                      renderSizes={cardRenderSizes}
                      setActiveListing={setActiveListing}
                      history={history}
                    />
                  );
                })
                .slice(0, 4)
            : clothingListings.map(l => {
                return (
                  <ListingCard
                    className={css.searchLink}
                    key={l.id.uuid}
                    listing={l}
                    renderSizes={cardRenderSizes}
                    setActiveListing={setActiveListing}
                    history={history}
                  />
                );
              })}
        </MediaQuery>
      </div> */}
      <div className={css.gallaryContainer}>
        {demoImages.map((item, index) => {
          return (
            <div
              className={css.imageDiv}
              onClick={() => {
                const params = {
                  pub_productCategory: 'clothing',
                  pub_productSubCategory: item?.key,
                };
                history.push(
                  createResourceLocatorString(
                    'SearchPage',
                    routeConfiguration(),
                    {},
                    params
                  )
                );
              }}
            >
              {item?.img && (
                <img src={item.img} alt={item?.alt} className={css.image} />
              )}
              {item?.label && (
                <div className={css.labelClothing}>{item?.label}</div>
              )}
            </div>
          );
        })}
      </div>
      {/* <div className={css.browseBtnDiv}>
        <PrimaryButton
          className={css.browseBtn}
          onClick={e => {
            const params = {
              pub_productCategory: 'clothing',
            };
            history.push(
              createResourceLocatorString(
                'SearchPage',
                routeConfiguration(),
                {},
                params
              )
            );
          }}
        >
          Browse All
        </PrimaryButton>
      </div> */}
    </div>
  );
};

SectionClothing.defaultProps = { rootClassName: null, className: null };

SectionClothing.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionClothing;
