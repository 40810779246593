import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form as FinalForm, Field } from 'react-final-form';
import classNames from 'classnames';
import { parse } from '../../util/urlHelpers';
import { intlShape, injectIntl } from '../../util/reactIntl';
import {
  FieldTextInput,
  Form,
  LocationAutocompleteInput,
} from '../../components';

import css from './TopbarSearchForm.module.css';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

import MediaQuery from 'react-responsive';

import { ReactComponent as SearchIcon } from '../../assets/searchIcon.svg';

const identity = v => v;

class TopbarSearchFormComponent extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.searchInput = null;
  }

  onChange(location, values) {
    if (location.selectedPlace) {
      // Note that we use `onSubmit` instead of the conventional
      // `handleSubmit` prop for submitting. We want to autosubmit
      // when a place is selected, and don't require any extra
      // validations for the form.
      this.props.onSubmit({ location, keywords: values.keywordSearch });
      // blur search input to hide software keyboard
      if (this.searchInput) {
        this.searchInput.blur();
      }
    }
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const {
            rootClassName,
            className,
            desktopInputRoot,
            intl,
            isMobile,
            values,
            history,
          } = formRenderProps;
          const classes = classNames(rootClassName, className);
          const desktopInputRootClass =
            desktopInputRoot || css.desktopInputRoot;

          // Allow form submit only when the place has changed
          const preventFormSubmit = e => {
            e.preventDefault();
            // const addressLatLng = new LatLng(
            //   values?.location?.predictions?.[0]?.geometry?.coordinates?.[1],
            //   values?.location?.predictions?.[0]?.geometry?.coordinates?.[0]
            // );

            // const addressBounds = locationBounds(addressLatLng, 1000);

            const latLngBounds = () => {
              const addressLatLng = values?.location?.predictions?.[0]?.bbox;
              const reverseLatLng = [];
              for (let i = addressLatLng.length - 1; i >= 0; i--) {
                reverseLatLng.push(addressLatLng[i]);
              }
              return reverseLatLng;
            };
            // =35.673315 %2C 97.395359 %2C 6.6718373 %2C 68.1152344
            // bounds=68.1152344%2C6.6718373%2C97.395359%2C35.de
            // bounds = 35.673315 & bounds = 97.395359 & bounds = 6.6718373 &
            // bounds = 68.1152344;
            let params = {};
            if (values?.keywordSearch) {
              params.keywords = values.keywordSearch;
            }
            if (values?.location?.search) {
              params.address = values.location.search;
              params.bounds = latLngBounds().join(',');
            }
            // console.log(666, values);
            history.push(
              createResourceLocatorString(
                'SearchPage',
                routeConfiguration(),
                {},
                // {
                //   keywords: values.keywordSearch,
                //   address: values.location.search,
                // }
                params
              )
            );
          };

          return (
            <Form
              className={classes}
              onSubmit={preventFormSubmit}
              enforcePagePreloadFor="SearchPage"
            >
              <div className={css.searchInputDiv}>
                <div className={css.keywordSearch}>
                  {/* <svg className={css.keywordSearchIcon} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                   <path d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z" stroke="black"                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                   <path d="M19 19L14.65 14.65" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                   </svg> */}
                  {/* <svg
                    className={css.keywordSearchIcon}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="#5F6368"
                  >
                    <path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
                  </svg> */}
                  <FieldTextInput
                    type="text"
                    name="keywordSearch"
                    className={css.keywordInput}
                    placeholder="Search for anything"
                  />
                </div>
                <span className={css.hDivider}></span>
                <Field
                  name="location"
                  format={identity}
                  render={({ input, meta }) => {
                    const { onChange, ...restInput } = input;

                    // Merge the standard onChange function with custom behaviur. A better solution would
                    // be to use the FormSpy component from Final Form and pass this.onChange to the
                    // onChange prop but that breaks due to insufficient subscription handling.
                    // See: https://github.com/final-form/react-final-form/issues/159
                    const searchOnChange = val => {
                      onChange(val);
                      this.onChange(val, values);
                    };

                    const searchInput = {
                      ...restInput,
                      onChange: searchOnChange,
                    };

                    return (
                      <div className={css.searchBox}>
                        {/* <svg
                          className={css.locationIcon}
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="20"
                          viewBox="0 0 16 20"
                          fill="none"
                        >
                          <path
                            d="M8 10C8.55 10 9.021 9.804 9.413 9.412C9.80433 9.02067 10 8.55 10 8C10 7.45 9.80433 6.979 9.413 6.587C9.021 6.19567 8.55 6 8 6C7.45 6 6.97933 6.19567 6.588 6.587C6.196 6.979 6 7.45 6 8C6 8.55 6.196 9.02067 6.588 9.412C6.97933 9.804 7.45 10 8 10ZM8 17.35C10.0333 15.4833 11.5417 13.7873 12.525 12.262C13.5083 10.7373 14 9.38333 14 8.2C14 6.38333 13.4207 4.89567 12.262 3.737C11.104 2.579 9.68333 2 8 2C6.31667 2 4.89567 2.579 3.737 3.737C2.579 4.89567 2 6.38333 2 8.2C2 9.38333 2.49167 10.7373 3.475 12.262C4.45833 13.7873 5.96667 15.4833 8 17.35ZM8 20C5.31667 17.7167 3.31267 15.5957 1.988 13.637C0.662667 11.679 0 9.86667 0 8.2C0 5.7 0.804333 3.70833 2.413 2.225C4.021 0.741667 5.88333 0 8 0C10.1167 0 11.979 0.741667 13.587 2.225C15.1957 3.70833 16 5.7 16 8.2C16 9.86667 15.3377 11.679 14.013 13.637C12.6877 15.5957 10.6833 17.7167 8 20Z"
                            fill="black"
                          />
                        </svg> */}
                        <LocationAutocompleteInput
                          className={
                            isMobile
                              ? css.mobileInputRoot
                              : desktopInputRootClass
                          }
                          isOpen={true}
                          iconClassName={
                            isMobile ? css.mobileIcon : css.desktopIcon
                          }
                          inputClassName={
                            isMobile ? css.mobileInput : css.desktopInput
                          }
                          predictionsClassName={
                            isMobile
                              ? css.mobilePredictions
                              : css.desktopPredictions
                          }
                          predictionsAttributionClassName={
                            isMobile ? css.mobilePredictionsAttribution : null
                          }
                          placeholder={intl.formatMessage({
                            id: 'TopbarSearchForm.placeholder',
                          })}
                          closeOnBlur={!isMobile}
                          inputRef={node => {
                            this.searchInput = node;
                          }}
                          input={searchInput}
                          meta={meta}
                        />
                      </div>
                    );
                  }}
                />
                <button className={css.searchBtn}>
                  {/* <MediaQuery minWidth={575}>Search</MediaQuery>
                <MediaQuery maxWidth={575}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="#5F6368"
                >
                  <path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
                </svg>
                </MediaQuery> */}
                  <SearchIcon />
                </button>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

const { func, string, bool } = PropTypes;

TopbarSearchFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  desktopInputRoot: null,
  isMobile: false,
};

TopbarSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  desktopInputRoot: string,
  onSubmit: func.isRequired,
  isMobile: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const TopbarSearchForm = injectIntl(TopbarSearchFormComponent);

export default TopbarSearchForm;
