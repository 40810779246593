import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
// import { OwnListingLink } from '../../components';
import { ReactComponent as Home } from '../../assets/footerIcon.svg';
import { ReactComponent as Support } from '../../assets/footerIcon2.svg';

// import { ReactComponent as SafeTrust } from '../../assets/Safe_and _trust_Siuhuu_icon.svg';
import { ReactComponent as SafeTrust } from '../../assets/footerIcon3.svg';

import css from './SectionHowItWorks.module.css';
import { PrimaryButton } from '../Button/Button';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

const SectionHowItWorks = props => {
  const {
    rootClassName,
    className,
    currentUserListing,
    currentUserListingFetched,
    history,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionHowItWorks.titleLineOne" />
        <br />
        <FormattedMessage id="SectionHowItWorks.titleLineTwo" />
      </div>
      <div className={css.steps}>
        <div className={css.step}>
          <Home className={css.iconSvg} />

          <h2 className={css.stepTitle}>
            <FormattedMessage id="SectionHowItWorks.part1Title" />
          </h2>
          <p className={css.stepText}>
            <FormattedMessage id="SectionHowItWorks.part1Text" />
          </p>
        </div>

        <div className={css.step}>
          <Support className={css.iconSvg} />

          <h2 className={css.stepTitle}>
            <FormattedMessage id="SectionHowItWorks.part2Title" />
          </h2>
          <p className={css.stepText}>
            <FormattedMessage id="SectionHowItWorks.part2Text" />
          </p>
        </div>

        <div className={css.step}>
          <SafeTrust className={css.iconSvg} />

          <h2 className={css.stepTitle}>
            <FormattedMessage id="SectionHowItWorks.part3Title" />
          </h2>
          <p className={css.stepText}>
            <FormattedMessage id="SectionHowItWorks.part3Text" />
          </p>
        </div>
      </div>
      <div className={css.lastLine}>
        Want to know how to sell products or services on Siuhuu?
      </div>
      <div className={css.browseBtnDiv}>
        <PrimaryButton
          className={css.browseBtn}
          onClick={() => {
            history.push(
              createResourceLocatorString(
                'HowItWorksPage',
                routeConfiguration(),
                {}
              )
            );
          }}
        >
          How it works
        </PrimaryButton>
      </div>
      {/* <div className={css.createListingLink}>
        <OwnListingLink
          listing={currentUserListing}
          listingFetched={currentUserListingFetched}
        >
          <FormattedMessage id="SectionHowItWorks.createListingLink" />
        </OwnListingLink>
      </div> */}
    </div>
  );
};

SectionHowItWorks.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionHowItWorks.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionHowItWorks;
